import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setArticles } from "../../redux/reducer";
import axios from "axios";

const Articles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (articles.length === 0) {
      getArticles();
    }
  }, []);

  const getArticles = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`https://6tjm4vdl9c.execute-api.us-east-2.amazonaws.com/list-article`);
      dispatch(setArticles(data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl h-full sm:p-4 p-2">
      <div className="flex font-semibold mb-6">
        <ArrowLeftIcon className="w-6 h-6 cursor-pointer mr-2" onClick={() => navigate("/chat")} />
        Back to chat
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <svg
            className="animate-spin -ml-1 mr-2 h-5 w-5 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
            ></path>
          </svg>
          Loading...
        </div>
      )}
      <div className="container-xl mx-auto overflow-y-auto mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[24rem_24rem_24rem] gap-6">
          {articles?.map((item, i) => (
            <div
              className="p-4 border rounded-xl bg-white shadow cursor-pointer hover:shadow-lg"
              key={i + 1}
              onClick={() => {
                navigate(`/article/${item?.urlSlug}`);
              }}
            >
              <img src={item?.imageAssets} className="h-60 w-full w-full object-cover rounded-md" />
              {/* h-48 w-full object-cover md:h-full md:w-48 */}
              <div className="text-xl font-bold mt-4">{item?.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Articles;

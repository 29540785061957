/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      title
      userId
      conversations {
        items {
          id
          historyConversationsId
          prompt
          description
          sender
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        userId
        conversations {
          items {
            id
            historyConversationsId
            prompt
            description
            sender
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPromptHistory = /* GraphQL */ `
  query GetPromptHistory($id: ID!) {
    getPromptHistory(id: $id) {
      id
      historyConversationsId
      prompt
      description
      sender
      createdAt
      updatedAt
    }
  }
`;
export const listPromptHistories = /* GraphQL */ `
  query ListPromptHistories(
    $filter: ModelPromptHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromptHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        historyConversationsId
        prompt
        description
        sender
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      cognitoSubId
      userEmail
      subscriptionId
      subscriptionName
      subscriptionActive
      subscriptionPrices
      subscriptionEndDate
      subscriptionDescription
      subscriptionType
      paymentMethod
      paymentCard
      count
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSubId
        userEmail
        subscriptionId
        subscriptionName
        subscriptionActive
        subscriptionPrices
        subscriptionEndDate
        subscriptionDescription
        subscriptionType
        paymentMethod
        paymentCard
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      id
      body
      title
      urlSlug
      imageAssets
      createdAt
      updatedAt
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        title
        urlSlug
        imageAssets
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Auth, Storage } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon, XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";

import AIBanner from "../assets/images/RoboIP.png";
import AIicon from "../assets/images/AIicon.png";
import InfoIcon from "../assets/icons/icon-inbox.svg";
import Dollar from "../assets/icons/icon-dollar-sign.svg";
import SideBar from "./chat/SideBar";

const Header = ({ privateContain }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [imageUrl, setImageUrl] = useState(null);
  const [isFooter, setisFooter] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setShowDrawer(windowWidth < 992);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getDetail();
  }, [user]);

  const getDetail = async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      if (data?.attributes?.picture) {
        const imageKey = `${data?.attributes?.sub}/${data?.attributes?.picture}`;
        const url = await Storage.get(imageKey);
        setImageUrl(url);
      }
    } catch (error) {
      console.log("header error: ", error);
    }
  };
  const scrollToTop = (elementId) => {
    var elmntToView = document.getElementById(elementId);
    elmntToView.scrollIntoView({
      behavior: "smooth",
    });
  };
  const locationChange = window.location.pathname;

  useEffect(() => {
    if (locationChange === "/") {
      setisFooter(true);
    } else {
      setisFooter(false);
    }
  }, [locationChange]);

  return (
    <>
      <div className="border-b border-black-100">
        <div
          className={`justify-between w-full items-center my-3
           ${privateContain === true ? "container-md md:px-10 px-4 flex" : "container sm:flex"}
           `}
        >
          {privateContain &&
            showDrawer &&
            window.location.pathname !== "/profile" &&
            window.location.pathname !== "/subscription" && (
              <>
                <button onClick={toggleDrawer}>
                  <Bars3Icon className="h-5 w-5" />
                </button>
                {isOpen && (
                  <div className="z-10 fixed inset-0 bg-gray-900 bg-opacity-50 flex items-start justify-start	">
                    <div className="bg-white w-64 h-full  ">
                      <button className="md:m-4 m-3 float-right" onClick={toggleDrawer}>
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                      <SideBar showDrawer={showDrawer} setIsOpen={(data) => setIsOpen(data)} isOpen={isOpen} />
                    </div>
                  </div>
                )}
              </>
            )}
          <img
            src={AIBanner}
            alt="banner"
            onClick={() => navigate("/")}
            className="cursor-pointer lg:w-[180px] w-[110px]"
          />
          {privateContain && <img src={imageUrl || AIicon} alt="banner" className="w-[32px] rounded-full " />}
          {isFooter && (
            <>
              <div className="flex md:gap-3 gap-1 sm:my-0 my-2">
                <div className="flex items-center cursor-pointer" onClick={() => scrollToTop("howWorks")}>
                  <img src={InfoIcon} alt="Your SVG" className="lg:h-8 lg:w-9 h-6 w-6" />
                  <span className="text-black-400 lg:text-lg text-[15px]">How it works</span>
                </div>
                <div className="flex items-center cursor-pointer" onClick={() => scrollToTop("Pricing")}>
                  <img src={Dollar} alt="Your SVG" className="lg:h-8 lg:w-9 h-6 w-6" />
                  <span className="text-black-400 lg:text-lg text-[15px]">Pricing</span>
                </div>
              </div>
              <div className="">
                <nav className="text-center">
                  <ul className="flex">
                    <li className="md:mr-4 mr-2 hover:text-blue-500 cursor-pointer">
                      <button
                        onClick={() => navigate("/signin")}
                        className="lg:text-base text-sm text-black-200 font-bold hover:bg-indigo-500 hover:text-white hover:border-indigo-500 border border-black-100 lg:rounded-2xl rounded-lg	lg:py-2 lg:px-4 py-1 px-3"
                      >
                        Login
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => navigate("/signup")}
                        className="lg:gap-3 gap-2 lg:text-base text-sm text-black-200 font-bold flex items-center border border-indigo-500 lg:rounded-2xl rounded-lg lg:py-2 lg:px-4 py-1 px-3 bg-indigo-500 text-white hover:bg-indigo-500 hover:text-white hover:border-indigo-500 "
                      >
                        Ask a question now
                        <ArrowRightIcon className="ml-1 lg:w-6 lg:h-6 w-4 h-4" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;

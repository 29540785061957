import React, { useEffect } from "react";

const data = [
  {
    id: "1",
    title: "Information We Collect",
    subPoint: [
      {
        id: "a",
        title: "Personal Information",
        paragraph:
          "When you create an account with RoboIP, we may collect certain personally identifiable information, including but not limited to your name, email address, contact information, and billing details. This information is necessary for us to provide you with access to our AI bot and manage your subscription effectively.",
      },
      {
        id: "b",
        title: "Usage Information",
        paragraph:
          "We may also collect non-personal information about your interaction with our website and platform. This includes information such as your IP address, browser type, operating system, referring URLs, and pages visited. We use this information to analyze trends, administer the site, track users' movements, and gather broad demographic information for aggregate use. This data helps us improve our services and enhance your overall experience.",
      },
    ],
  },
  {
    id: "2",
    title: "How We Use Your Information",
    subPoint: [
      {
        id: "a",
        title: "Providing and Improving Services",
        paragraph:
          "We use the information we collect to deliver our services, respond to your inquiries, and fulfill your requests. This includes providing you with access to our AI bot, answering your questions based on the content trained from the MPEP, and managing your account and subscription. We may also use your information to personalize and enhance your user experience, improve our platform, and develop new features and offerings.",
      },
      {
        id: "b",
        title: "Communication",
        paragraph:
          "We may use your contact information to communicate with you regarding your account, subscription, and any updates or changes to our services. We may also send you promotional emails, newsletters, or other marketing materials related to RoboIP. You can opt out of receiving such communications by following the instructions provided in the email or contacting us directly.",
      },
      {
        id: "c",
        title: "Legal Compliance",
        paragraph:
          " In certain situations, we may be required to disclose your personal information to comply with applicable laws, regulations, legal processes, or governmental requests. We will take reasonable steps to notify you in advance, unless prohibited by law, when such disclosure is legally required.",
      },
    ],
  },
  {
    id: "3",
    title: "Data Security ",
    paragraph:
      " We take the security of your personal information seriously and employ industry-standard measures to protect it against unauthorized access, disclosure, alteration, and destruction. However, no method of transmission or electronic storage is 100% secure, and we cannot guarantee absolute security. ",
  },
  {
    id: "4",
    title: "Third-Party Services and Links ",
    paragraph:
      " Our website and platform may contain links to third-party websites or services. These external links are provided for your convenience and do not imply endorsement or responsibility for the content, privacy practices, or terms of use of such websites. We encourage you to review the privacy policies and terms of these third parties before engaging with their services. ",
  },
  {
    id: "5",
    title: "Children's Privacy ",
    paragraph:
      " Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If we become aware that we have inadvertently collected personal information from a child under the age of 18, we will take reasonable steps to delete such information from our records. ",
  },
  {
    id: "6",
    title: "Your Choices and Rights ",
    paragraph:
      " You have the right to access, update, correct, or delete your personal information. You may also have the right to restrict or object to certain processing activities and request the transfer of your data. To exercise these rights or to request further information, please contact us using the contact details provided at the end of this Privacy Policy. ",
  },
  {
    id: "7",
    title: "Changes to This Privacy Policy ",
    paragraph:
      " We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective upon posting the updated version on our website. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information. Your continued use of our website and services after any changes have been made constitutes your acceptance of the revised Privacy Policy ",
  },
  {
    id: "8",
    title: "Data Retention ",
    paragraph:
      " We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When we no longer need your information, we will securely dispose of it in accordance with applicable laws and regulations. ",
  },
  {
    id: "9",
    title: "International Transfers ",
    paragraph:
      " RoboIP operates globally and may transfer your personal information to countries outside your own. These countries may have different data protection laws than your country of residence. By using our services and providing your information, you consent to the transfer of your data to these countries, including those with less stringent data protection laws. ",
  },
  {
    id: "10",
    title: "Consent to Processing in the United States ",
    paragraph:
      " If you are located outside the United States, please note that your personal information may be processed and stored in the United States, where our servers are located. By using our services and providing your information, you consent to the processing and transfer of your data to the United States. ",
  },
  {
    id: "3",
    title: "Contact Us ",
    paragraph: [
      {
        text: "If you have any questions, concerns, or requests regarding this Privacy Policy, or if you would like to exercise your rights or update your information, please contact us at:",
      },
      {
        text: "RoboIP (Wysebridge, d.b.a RoboIP) 830 Welsh Road 913, Huntingdon Valley, PA 19006 Email: privacy@roboip.com",
      },
      {
        text: "We will respond to your inquiries and address your concerns in accordance with applicable privacy laws.",
      },
      {
        text: "Thank you for trusting RoboIP with your privacy. We are committed to safeguarding your personal information and providing you with a secure and seamless experience on our platform.",
      },
      { text: "Last updated: 6/7/23" },
    ],
  },
];
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <div className="container">
      <div className="md:mb-12 mb-6 md:mt-10 mt-6">
        <h2 className="md:text-5xl text-3xl font-bold ">Privacy Policy</h2>
        <p className="sm:text-lg text-base sm:mt-3 mt-1 mb-6">
          Effective Date: 6/7/2023
        </p>
        <p className="sm:text-lg text-base mb-8">
          At RoboIP, we value your privacy and are committed to protecting your
          personal information. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your data when you visit our website, use
          our services, or interact with our platform. Please take a moment to
          familiarize yourself with our practices and let us know if you have
          any questions or concerns.
        </p>

        <div>
          {data &&
            data.map((row, index) => (
              <div key={index}>
                <ul className="flex gap-2">
                  <li>{index + 1}.</li>
                  <li className="sm:text-lg text-base">
                    <div className="font-bold"> {row.title} </div>
                    {Array.isArray(row.paragraph)
                      ? row.paragraph.map((rp, d) => (
                          <div key={d}>{rp.text}</div>
                        ))
                      : row.paragraph}
                  </li>
                </ul>
                {row.hasOwnProperty("subPoint") &&
                  row.subPoint.map((row) => (
                    <>
                      <ul className="gap-2 ml-6">
                        <li className="sm:text-lg text-base">
                          <ul className="flex gap-1">
                            <li> {row.id}. </li>
                            <li>
                              <span className="font-bold">{row.title} :</span>
                              {row.paragraph}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import { useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PublicContainer = ({ children, isSingup, tabTitle }) => {
  const navigate = useNavigate();
  const locationPath = window.location.pathname;

  const checkAuth = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      if (locationPath.includes("/article")) return;
      navigate("/chat");
    } catch (error) {
      // console.log(error);
    }
  }, [navigate]);

  useEffect(() => {
    if (!isSingup) {
      checkAuth();
    }
  });

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  return (
    <div className="h-screen flex flex-col text-black-600 font-inter">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default PublicContainer;

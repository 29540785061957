import React, { useState } from "react";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  UserIcon,
  AtSymbolIcon,
  KeyIcon,
  PhoneIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

import AIicon from "../../assets/images/RoboIP Head.png";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  phoneNumber: yup
    .string()
    .required("phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    ),
});

const initialValues = {
  firstName: "",
  phoneNumber: "",
  email: "",
  password: "",
};

export default function Signup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const onSubmit = async (values) => {
    // console.log("values: ", values);
    try {
      setIsLoading(true);
      const data = await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          given_name: values.firstName,
          phone_number: `+1${values.phoneNumber.replace(/[-()]/g, "")}`,
        },
      });
      if (data) {
        setIsLoading(false);
        navigate("/verification", {
          state: { email: values.email, password: values.password },
        });
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signup error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <div>
      <div className="flex flex-col my-10 md:p-10 p-4 md:w-[450px] w-[280px] m-auto md:shadow-2xl shadow-xl rounded-2xl ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            src={AIicon}
            alt="banner"
            className="md:h-[58px] md:w-[58px] h-[40px] w-[40px]"
          />
          <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight">
            Rock The MPEP
          </h2>
          <p>Supercharge your IP and Patent Law Career!</p>
        </div>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div>
                <div className="flex relative">
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                  />
                  <UserIcon className="md:w-5 md:h-5 w-4 h-4 cursor-pointer absolute right-3 top-3 " />
                </div>
                <ErrorMessage
                  name="firstName"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:mt-8 mt-4">
                <div className="flex relative">
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                  />
                  <AtSymbolIcon className="md:w-5 md:h-5 w-4 h-4 cursor-pointer absolute right-3 top-3 " />
                </div>
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:mt-8 mt-4">
                <div className="flex relative">
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    placeholder="Phone"
                    className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                  />
                  <PhoneIcon className="md:w-5 md:h-5 w-4 h-4 cursor-pointer absolute right-3 top-3 " />
                </div>
                <ErrorMessage
                  name="phoneNumber"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:mt-8 mt-4">
                <div className="flex relative">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                  />
                  <KeyIcon className="md:w-5 md:h-5 w-4 h-4 cursor-pointer absolute right-3 top-3  " />
                </div>
                <ErrorMessage
                  name="password"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="mt-10 mb-4">
                <label className="flex items-start ">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="leading-4 pl-2 lg:text-lg text-sm">
                    I accept the ToS and Privacy Policies.
                  </span>
                </label>
              </div>
              <button
                type="submit"
                disabled={!isChecked ? true : false}
                // disabled={isLoading}
                className={`w-full rounded-2xl py-2 px-4 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed flex justify-center"
                    : ""
                }   ${!isChecked ? "bg-blue-400	" : "bg-indigo-500"}`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                    ></path>
                  </svg>
                ) : (
                  <span className="flex justify-between lg:text-lg text-[15px] font-bold text-white">
                    Let’s Do This!{" "}
                    <ArrowRightIcon className="md:w-6 md:h-6 w-5 h-5 ml-2" />
                  </span>
                )}
              </button>
            </Form>
          </Formik>
          <div className="mt-12 lg:text-lg text-base text-center">
            <p className="text-black-200 font-bold mb-2 cursor-pointer italic">
              Already have an account ?
            </p>
            <Link
              to="/signin"
              className="font-semibold leading-6 text-indigo-500 hover:text-indigo-600"
            >
              Sign in here
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  ArrowRightIcon,
  ArrowUpIcon,
  StarIcon,
  TruckIcon,
  UsersIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";

import mpepweb from "../../assets/images/mpepweb.png";
import RoboIP from "../../assets/images/RoboIP.png";
import idea1 from "../../assets/images/idea1.png";
import cpu from "../../assets/icons/icon-cpu.svg";
import info from "../../assets/icons/icon-info.svg";
import shopping from "../../assets/icons/icon-shopping-cart.svg";
import users from "../../assets/icons/icon-users.svg";
import TopBarDesktop from "../../assets/images/TopBarDesktop.png";
import landingScreenshot from "../../assets/images/landing-screenshot1.png";
import RoboIP_main_landing_video from "../../assets/images/RoboIP_main_landing_video.mp4";

const faqs = [
  {
    question: "How good is this product?",

    answer:
      "While this is perhaps a subjective inquiry, it is one that we’ve received! Think of RoboIP’s MPEPgpt like your personal sherpa to the Manual of PATENT EXAMINING PROCEDURE (MPEP). What would perhaps take you tens of minutes or even hours to deduce and infer by wading through the MPEP, now takes a fraction of that time. Not only saving you time, the platform can effortlessly rephrase and summarize for you in different tones, for different audiences or education levels, allowing you unparalleled access to quantifying the often complex landscape that is the MPEP. Never before has the MPEP been so accessible, to as many people, as what RoboIP’s MPEPgpt provides.   ",
    paragraph2:
      "So, we’ll leave it up to you to decide for yourself. We know it’ll save your loads of time and accelerate your quantification of the rules and procedures of the MPEP.",
  },
  {
    question: "How do I know the answers are accurate?",
    answer:
      "While RoboIP’s MPEPgpt is by no means meant to replace a certified and seasoned Patent Practitioner or IP law expert, you can be confident in the answers provided to your inquires as RoboIP’s models and datasets are continuously being refined and attuned to the MPEP itself.  Think of it this way, you are able to tap into a mainframe database that has assimilated the MPEP more comprehensively than any human could, and is returning you answers based on that super-power.",
    paragraph2:
      "Oh, and in case that isn’t enough for you, we’ve let dozens of IP attorneys and Patent Practitioners loose on this before releasing it, and they’ve agreed that the results of RoboIP’s MPEPgpt are of the highest caliber.",
  },
  {
    question: "What is your refund policy?",
    answer:
      "By subscribing to our services, you authorize RoboIP to charge the applicable fees to your chosen payment method. Subscription fees are non-refundable, and you are responsible for timely payment of all charges associated with your account. You may cancel your subscription at any time. We will no longer charge you anything once you cancel your subscription. We reserve the right to modify our pricing, pricing policies, features and access restrictions at any time",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "You may cancel your subscription at any time through your account settings. Upon cancellation, your access to the AI bot and related services will continue until the end of the billing period, and no further charges will be incurred.",
  },
  // Add more FAQ items as needed
];
const LandingPage = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setTimeout(() => {
        setActiveIndex(index);
      }, 200);
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="md:my-14 my-8">
      <div className="container mx-auto">
        {/* ***** Video section ***** */}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 lg:mb-40 sm:mb-20 mb-12">
          <div>
            <ReactPlayer
              playing={true}
              controls={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
              url={RoboIP_main_landing_video}
            />
          </div>
          <div className="lg:w-[500px] lg:ml-16">
            <h1 className="lg:text-6xl md:text-4xl sm:text-3xl text-2xl text-2xl font-black leading-tight">
              <span className="text-indigo-500"> MPEP</span> gpt and
              <span className="text-indigo-500"> IPLaw </span>
              Expert for Everyone.
            </h1>
            <p className="lg:mt-5 lg:mb-8 mt-2 mb-5">
              Instantly answer all your Patent and IP Law questions with your
              own MPEPgpt chatbot!
            </p>
            <button
              onClick={() => navigate("/signin")}
              className="lg:gap-3 gap-2 lg:text-base text-sm text-black-200 font-bold flex items-center border border-indigo-500 lg:rounded-2xl rounded-lg lg:py-2 lg:px-4 py-1 px-3 bg-indigo-500 text-white hover:bg-indigo-500 hover:text-white hover:border-indigo-500 "
            >
              Ask a question now
              <ArrowRightIcon className="ml-1 lg:w-6 lg:h-6 w-4 h-4" />
            </button>
          </div>
        </div>
        {/* ***** How it works ***** */}
        <div
          className="grid md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-3 lg:mb-40 sm:mb-20 mb-12"
          id="howWorks"
        >
          <div className="lg:w-[304px] w-full border border-black-300 rounded-2xl	md:px-9	md:py-7 px-4 py-3	text-center	flex flex-col justify-between gap-5 my-0 mx-auto">
            <img
              className="my-0 mx-auto border border-black-300 p-3 rounded-2xl"
              src={mpepweb}
              alt="banner"
            />
            <ArrowUpIcon className="animate-bounce lg:h-14 lg:w-14 h-10 w-10 mx-auto" />
            <img
              src={RoboIP}
              alt="banner"
              className="lg:w-[150px] w-[120px] my-0 mx-auto"
            />
            <ArrowUpIcon className=" animate-bounce lg:w-14 h-10 w-10 mx-auto" />
            <img
              src={idea1}
              alt="banner"
              className="lg:w-[100px] w-[80px] my-0 mx-auto"
            />
          </div>
          <div className="col-span-2">
            <h1 className="lg:text-6xl md:text-4xl sm:text-3xl text-2xl font-black leading-tight md:max-w-[635px] md:mt-0 mt-3 md:mb-7 mb-4">
              <span className="text-indigo-500">Get expert answers</span> to
              your Patent and IP Law questions
            </h1>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-7">
              <div>
                <div className="flex items-center lg:mb-4 md:mb-2">
                  <img src={cpu} alt="icon" />
                  <h5 className="font-bold lg:text-2xl md:text-md text-md">
                    Neural Trained on MPEP
                  </h5>
                </div>
                <p className="text-black-400 ml-3 lg:leading-8 leading-6 lg:text-lg md:text-base">
                  RoboIP’s MPEPgpt chatbot was extensively trained on the
                  USPTO’s latest edition of the Manual of Patent Examining
                  Procedure.
                </p>
              </div>
              <div>
                <div>
                  <div className="flex items-center lg:mb-4 md:mb-2">
                    <img src={info} alt="icon" />
                    <h5 className="font-bold lg:text-2xl md:text-md text-md">
                      Continuous Updates
                    </h5>
                  </div>
                  <p className="text-black-400 ml-3 lg:leading-8 leading-6 lg:text-lg md:text-base">
                    We’re continually tuning and refining RoboIP’s training
                    around the MPEP and have more features in the pipeline.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <div className="flex items-center lg:mb-4 md:mb-2">
                    <img src={shopping} alt="icon" />
                    <h5 className="font-bold lg:text-2xl md:text-md text-md">
                      Affordable for all
                    </h5>
                  </div>
                  <p className="text-black-400 ml-3 lg:leading-8 leading-6 lg:text-lg md:text-base">
                    We believe everyone should have affordable access to
                    authoritative answers for their IP and Patent Law concerns.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <div className="flex items-center lg:mb-4 md:mb-2">
                    <img src={users} alt="icon" />
                    <h5 className="font-bold lg:text-2xl md:text-md text-md">
                      ️️ MPEPgpt Chatbot for everyone
                    </h5>
                  </div>
                  <p className="text-black-400 ml-3 lg:leading-8 leading-6 lg:text-lg md:text-base">
                    Now you can get immediate answers to any patent or IP law
                    question you might have!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** Hyper-accelerate ***** */}
        <div className="lg:mb-40 sm:mb-20 mb-12">
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 items-center mb-6">
            <div className="col-span-2">
              <h1 className="lg:text-6xl md:text-4xl sm:text-3xl text-2xl font-black leading-tight">
                <span className="text-indigo-500">
                  Hyper-
                  <br />
                  accelerate{" "}
                </span>
                your <br />
                Patent and IP Law
                <br /> career. Crush your <br />
                Patent Law Studies.
              </h1>
            </div>
            <div>
              <button
                onClick={() => navigate("/signin")}
                className="lg:gap-3 gap-2 lg:text-base text-sm text-black-200 font-bold flex items-center border border-indigo-500 lg:rounded-2xl rounded-lg lg:py-2 lg:px-4 py-1 px-3 bg-indigo-500 text-white hover:bg-indigo-500 hover:text-white hover:border-indigo-500 "
              >
                Ask a question now
                <ArrowRightIcon className="ml-1 lg:w-6 lg:h-6 w-4 h-4" />
              </button>
            </div>
          </div>
          <div className="relative">
            <img
              src={TopBarDesktop}
              alt="TopBarDesktop"
              className="mx-auto max-w-full h-auto"
            />
            <img
              src={landingScreenshot}
              alt="landingScreenshot"
              className="mx-auto shadow-lg max-w-full h-auto"
            />
            <div className="flex sm:flex-row flex-col block gap-3 md:mt-0 mt-4">
              <div className="sm:w-auto w-full md:absolute top-[120px] left-0 lg:p-6 p-3 max-w-sm mx-auto bg-white rounded-xl shadow-lg md:flex items-center md:space-x-4">
                <div className="shrink-0 bg-[#dce6fc] md:p-2 p-1 rounded-4xl inline-block md:mb-0 mb-2">
                  <StarIcon className="lg:h-7 lg:w-7 h-6 w-6 text-indigo-500" />
                </div>
                <div>
                  <div className="lg:text-xl text-base font-medium text-black md:mb-1 mb-2">
                    Review Past Questions
                  </div>
                  <p className="text-slate-500 lg:text-base text-sm">
                    See prior questions and answers
                  </p>
                </div>
              </div>
              <div className="sm:w-auto w-full md:absolute bottom-[-50px] left-0 right-0 lg:p-6 p-3 max-w-sm mx-auto bg-white rounded-xl shadow-lg md:flex items-center md:space-x-4">
                <div className="shrink-0 bg-black-200 md:p-2 p-1 rounded-4xl inline-block md:mb-0 mb-2">
                  <TruckIcon className="lg:h-7 lg:w-7 h-6 w-6 text-white" />
                </div>
                <div>
                  <div className="lg:text-xl text-base font-medium text-black md:mb-1 mb-2">
                    Coming Soon
                  </div>
                  <p className="text-slate-500 lg:text-base text-sm">
                    Notes, collaboration, and more
                  </p>
                </div>
              </div>
              <div className="sm:w-auto w-full md:absolute bottom-[80px] right-0 lg:p-6 p-3 max-w-sm mx-auto bg-white rounded-xl shadow-lg md:flex items-center md:space-x-4">
                <div className="shrink-0 bg-[#dce6fc] md:p-2 p-1 rounded-4xl inline-block md:mb-0 mb-2">
                  <UsersIcon className="lg:h-7 lg:w-7 h-6 w-6 text-indigo-500" />
                </div>
                <div>
                  <div className="lg:text-xl text-base font-medium text-black md:mb-1 mb-2">
                    Immediate Answers
                  </div>
                  <p className="text-slate-500 lg:text-base text-sm">
                    Ask any Patent or IP law question and get expert answers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ***** plan ***** */}
        <div id="Pricing" className="lg:mb-28 sm:mb-20 mb-12">
          <h2 className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold">
            Choose your plan
          </h2>
          <h3 className="lg:text-3xl md:text-2xl text-base font-bold text-black-200 md:mb-6 mb-3">
            Pay by month or year. Enterprise plans are also available.
          </h3>
          <div className="flex md:flex-row flex-col justify-center gap-7">
            <div className="md:m-0 m-auto text-center lg:p-6 p-4 shadow-lg rounded-xl w-[270px] flex flex-col justify-between">
              <h3 className="md:text-3xl text-xl leading-loose font-bold text-black-200">
                Essential
              </h3>
              <h6 className="md:text-3xl sm:text-2xl text-[22px] font-bold">
                $119/
                <span className="text-lg font-normal text-black-200">mo</span>
              </h6>
              <p className="text-indigo-500 text-sm font-extrabold sm:mt-4 mt-3">
                OR
              </p>
              <h6 className="text-lg font-bold text-black-100 sm:my-5 my-3">
                $1190<span className="text-indigo-500">/</span>yr
              </h6>
              <ul className="list-inside list-disc text-left sm:min-h-[100px] min-h-[80px]">
                <li className="leading-8">Unlimited Chats</li>
                <li className="leading-8">30 day storage of answers</li>
              </ul>
              <button
                onClick={() => navigate("/signup")}
                className="sm:mt-10 mt-4 w-full flex justify-center rounded-2xl bg-indigo-500 lg:p-4 p-2 md:text-lg sm:text-base text-[14px] font-bold leading-6 text-white"
              >
                Get started!
              </button>
            </div>
            <div className="md:m-0 m-auto text-center lg:p-6 p-4 shadow-lg rounded-xl w-[270px] flex flex-col justify-between">
              <h3 className="md:text-3xl text-xl leading-loose font-bold text-black-200">
                Enterprise
              </h3>
              <h6 className="md:text-3xl sm:text-2xl text-[22px] font-bold">
                $--/
                <span className="text-lg font-normal text-black-200">mo</span>
              </h6>
              <p className="text-indigo-500 text-sm font-extrabold sm:mt-4 mt-3">
                OR
              </p>
              <h6 className="text-lg font-bold text-black-100 sm:my-5 my-3">
                $--<span className="text-indigo-500">/</span>yr
              </h6>
              <ul className="list-inside list-disc text-left sm:min-h-[100px] min-h-[80px]">
                <li className="leading-8">Unlimited Chats</li>
                <li className="leading-8">Extended answer storage</li>
                <li className="leading-8">Multi-seat usage discounts</li>
              </ul>
              <button className="sm:mt-10 mt-4 w-full flex justify-center rounded-2xl bg-indigo-500 lg:p-4 p-2 md:text-lg sm:text-base text-[14px] font-bold leading-6 text-white">
                <a href="mailto:enterprise@roboip.ai" className="w-full">
                  Contact sales
                </a>
              </button>
            </div>
            <div className="md:m-0 m-auto text-center lg:p-6 p-4 shadow-lg rounded-xl w-[270px] flex flex-col justify-between bg-[url('/src/assets/images/DotsPlat.png')]">
              <h3 className="md:text-3xl text-xl leading-loose font-bold text-black-200">
                PRO
              </h3>
              <h6 className="md:text-3xl sm:text-2xl text-[22px] font-bold text-black-100 sm:my-5 my-3">
                $169/
                <span className="text-lg font-normal text-black-200">mo</span>
              </h6>
              <h6 className="text-lg font-bold text-black-100 my-5">
                $1690<span className="text-indigo-500">/</span>yr
              </h6>
              <ul className="list-inside list-disc text-left sm:min-h-[100px] min-h-[80px]">
                <li className="leading-8">Unlimited Chats</li>
                <li className="leading-8">Unlimited answer storage</li>
                <li className="leading-8">
                  Notes, collaboration, sharing, and profile building
                </li>
              </ul>
              <button
                disabled
                className="italic uppercase sm:mt-10 mt-4 w-full flex justify-center rounded-2xl bg-black-100 lg:p-4 p-2 md:text-lg sm:text-base text-[14px] font-bold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                COMING SOON
              </button>
            </div>
          </div>
        </div>
        {/* ***** faq ***** */}
        <div>
          <h2 className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-bold md:mb-5 mb-3">
            FAQ
          </h2>
          <div>
            <div className="grid grid-cols-1 gap-4">
              {faqs.map((faq, index) => (
                <div key={index}>
                  <button
                    className={`shadow-md w-full flex justify-between items-center p-4 rounded-lg ${
                      activeIndex === index ? "bg-indigo-500 text-white" : ""
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-base font-semibold">
                      {faq.question}
                    </span>
                    {activeIndex === index ? (
                      <MinusIcon className="lg:h-5 lg:w-5 h-3 w-3 text-white" />
                    ) : (
                      <PlusIcon className="lg:h-5 lg:w-5 h-3 w-3" />
                    )}
                  </button>
                  {activeIndex === index && (
                    <div
                      className={`overflow-hidden transform transition duration-500	ease-in-out p-4 rounded-lg shadow-lg ${
                        activeIndex === index ? "max-h-full" : "max-h-0"
                      }`}
                    >
                      <p>{faq.answer}</p>
                      <p className="mt-3">{faq.paragraph2}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

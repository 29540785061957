import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import AWS from "aws-sdk";
import { ToastContainer, toast } from "react-toastify";

import { getUsers } from "../../graphql/queries";

AWS.config.update({
  region: "us-east-2",
  accessKeyId: "AKIARSIF3GNQG2ZYGE5L",
  secretAccessKey: "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",
});

const SubscriptionDetail = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [subscriptions, setSubscriptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      const queryResponse = await API.graphql({
        query: getUsers,
        variables: { id: data?.attributes?.sub },
      });
      if (queryResponse?.data?.getUsers !== null) {
        setSubscriptions(queryResponse?.data?.getUsers);
      }
    } catch (error) {
      toast.error(error.message || "Somithing went wrong!");
    }
  };

  const cancelSubscription = async () => {
    try {
      setIsLoading(true);
      const lambda = new AWS.Lambda();
      const params = {
        FunctionName: "cancel-strip-subscription",
        Payload: JSON.stringify({
          subscriptionId: subscriptions.subscriptionId,
          userId: user?.sub,
        }),
      };
      const response = await lambda.invoke(params).promise();
      const res = JSON.parse(response.Payload);
      setIsLoading(false);
      if (res.statusCode !== 200) {
        toast.error("Somithing went wrong, please try after sometime!");
      } else {
        toast.success("Subscription cancelled");
        getSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Subscription cancel error: ", error);
      toast.error(error.message || "Somithing went wrong!");
    }
  };

  return (
    <div className="container gap-10">
      {subscriptions?.subscriptionActive === false ||
      subscriptions?.subscriptionActive === null ? (
        <>
          <div className="my-10">
            <div className="flex justify-evenly	 items-center gap-6">
              <ArrowLeftIcon
                fontSize={10}
                className="w-7 h-7 cursor-pointer "
                onClick={() => navigate("/")}
              />
              <h1 className="text-center md:text-4xl text-2xl font-bold uppercase sm:mb-10 mb-4">
                SUGGESTED UPGRADE
              </h1>
            </div>
            <div className="flex flex-auto md:flex-row flex-col	justify-center gap-6 md:items-stretch	items-center">
              <div className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-100 text-center px-10 py-6 shadow-lg rounded-xl flex flex-col  gap-5 sm:w-[400px] w-[290px]">
                <h2 className="md:text-3xl text-2xl font-bold mb-4">
                  Subscription
                </h2>
                <div>
                  <span className="font-bold text-lg">current:</span>
                  <span className="text-lg"> Free</span>
                </div>
                <div>
                  <span className="font-bold text-lg">Active:</span>
                  <span className="text-lg">
                    {subscriptions?.subscriptionActive || "Yes"}
                  </span>
                </div>
                <div>
                  <span className="font-bold text-lg">Payment Method:</span>
                  <span className="text-lg">
                    {subscriptions?.paymentMethod || "n/a"}
                  </span>
                </div>
                <div>
                  <span className="font-bold text-lg">Billing Amount:</span>
                  <span className="text-lg">
                    $ {subscriptions?.subscriptionPrices || "0.00"}
                  </span>
                </div>
                <div>
                  <span className="font-bold text-lg">Next Billing:</span>
                  <span className="text-lg">
                    $ {subscriptions?.subscriptionPrices || "n/a"}
                  </span>
                </div>
              </div>

              <div className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-100 text-center px-10 py-6 shadow-lg rounded-xl flex flex-col justify-between sm:w-[400px] w-[290px]">
                <h3 className="md:text-3xl text-2xl leading-loose font-bold text-black-200">
                  Essential
                </h3>
                <h6 className="md:text-3xl text-xl font-bold">
                  $119/
                  <span className="text-lg font-normal text-black-200">
                    Month
                  </span>
                </h6>
                <p className="text-indigo-500 text-sm font-extrabold mt-4">
                  OR
                </p>

                <h6 className="md:text-3xl text-xl font-bold">
                  $1190/
                  <span className="text-lg font-normal text-black-200">
                    Year
                  </span>
                </h6>

                <ul className="list-disc text-left min-h-[100px] md:mt-10 mt-4">
                  <li className="leading-8">Unlimited Chats</li>
                  <li className="leading-8">30 day storage of answers</li>
                </ul>
                <button
                  onClick={() => navigate("/pricing")}
                  className="md:mt-10 mt-4 w-full flex justify-center rounded-2xl bg-indigo-500 md:py-4 py-2 text-lg font-bold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Upgrade Today!
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-auto justify-center gap-6 sm:mt-20 mt-7">
            <div>
              <div className="text-center sm:px-10 sm:py-8 md:px-5 md:py-4 p-3 shadow-lg rounded-xl flex flex-col justify-between">
                <div className="flex justify-between items-center gap-6">
                  <ArrowLeftIcon
                    fontSize={10}
                    className="w-7 h-7 cursor-pointer "
                    onClick={() => navigate("/")}
                  />
                  <h3 className="md:text-[28px] text-xl sm:leading-loose leading-8 font-bold text-black-600">
                    Current Subscription
                  </h3>
                </div>
                <div className="flex flex-col md:gap-6 gap-3 sm:my-10 my-6">
                  <div className="">
                    <span className="font-semibold md:text-lg text-base">
                      Current :{" "}
                    </span>
                    <span className="md:text-lg text-base text-black-400">
                      {subscriptions?.subscriptionName}
                    </span>
                  </div>
                  <div className="">
                    <span className="font-semibold md:text-lg text-base">
                      Active :{" "}
                    </span>
                    <span className="md:text-lg text-base text-black-400">
                      {subscriptions?.subscriptionActive ? "Yes" : "No"}
                    </span>
                  </div>
                  <div className="">
                    <span className="font-semibold md:text-lg text-base">
                      Payment Method :{" "}
                    </span>
                    <span className="md:text-lg text-base text-black-400">
                      ****{subscriptions?.paymentCard}
                    </span>
                  </div>
                  <div className="">
                    <span className="font-semibold md:text-lg text-base">
                      Billing Amount :{" "}
                    </span>
                    <span className="md:text-lg text-base text-black-400">
                      ${subscriptions?.subscriptionPrices}/
                      {subscriptions?.subscriptionType}
                    </span>
                  </div>
                  <div className="">
                    <span className="font-semibold md:text-lg text-base">
                      Next Billing :{" "}
                    </span>
                    <span className="md:text-lg text-base text-black-400">
                      {subscriptions?.subscriptionEndDate?.split("T")[0]}
                    </span>
                  </div>
                </div>
                <button
                  onClick={cancelSubscription}
                  className="w-full flex justify-center rounded-2xl bg-black-300 transition duration-50 ease-out md:ease-in hover:bg-black-200 md:py-4 py-2 sm:text-lg text-base font-bold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                      ></path>
                    </svg>
                  ) : (
                    <>
                      <strong>Cancel Subscription </strong>
                      <ArrowRightIcon className="ml-2 w-6 h-6" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default SubscriptionDetail;

import React, { useState } from "react";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import AIBanner from "../../assets/images/RoboIP.png";

const validationSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export default function Signin() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      const data = await Auth.signIn(values.email, values.password);
      if (data) {
        setIsLoading(false);
        navigate("/chat");
      } else {
        setIsLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("signin error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <div>
      <div className="my-10 md:p-10 p-4 md:w-[450px] w-[280px] m-auto md:shadow-2xl shadow-xl rounded-2xl ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            src={AIBanner}
            alt="banner"
            className="mx-auto md:w-[170px] w-[120px] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <h2 className="md:mt-6 mt-2 text-center text-lg font-bold leading-9 tracking-tight">
            Sign in to your account
          </h2>
        </div>

        <div className="md:mt-10 mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                />
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="md:mt-8 mt-4">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="w-full border border-black-100 md:text-lg text-sm rounded-[10px] py-2 px-4 pr-10"
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:text-lg sm:text-base text-sm text-right font-bold mt-3 mb-8">
                <Link
                  to="/forgotPassword"
                  className="font-bold text-indigo-500 hover:text-indigo-600"
                >
                  Forgot password?
                </Link>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full rounded-2xl py-2 px-4 bg-indigo-500 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed flex justify-center"
                    : "flex justify-center"
                }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                    ></path>
                  </svg>
                ) : (
                  <span className="w-full flex justify-between lg:text-lg text-[15px] font-bold text-white">
                    Login
                    <ArrowRightIcon className="md:w-6 md:h-6 w-5 h-5 ml-2" />
                  </span>
                )}
              </button>
            </Form>
          </Formik>
          <div className="mt-12 lg:text-lg text-base text-center">
            <p className="text-black-200 font-bold md:mb-4 mb-2 cursor-pointer italic">
              No account yet?
            </p>
            <Link
              to="/signup"
              className="font-semibold leading-6 text-indigo-500 hover:text-indigo-600"
            >
              Signup for free today
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

import React, { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  Container,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";

import { listUsers } from "../../graphql/queries";

const headCells = [
  {
    id: "mail",
    numeric: false,
    label: "Email",
  },
  {
    id: "subName",
    numeric: true,
    label: "Subscription Name",
  },
  {
    id: "subStatus",
    numeric: true,
    label: "Subscription Status",
  },
  {
    id: "subPrice",
    numeric: true,
    label: "Subscription Prices",
  },
  // {
  //   id: "createdAt",
  //   numeric: true,
  //   label: "Subscription Start",
  //   sortable: true,
  // },
  {
    id: "subEnd",
    numeric: true,
    label: "Subscription End",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListUserTbl() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = useState([]);

  const getUsersList = React.useCallback(async () => {
    try {
      const todoData = await API.graphql(graphqlOperation(listUsers));
      const array = todoData.data.listUsers.items;

      array.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setUsers(array);
    } catch (error) {
      console.error("Error fetching users list: ", error);
    }
  }, []);

  React.useEffect(() => {
    getUsersList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => ascendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const ascendingComparator = (a, b, orderBy) => {
    if (a[orderBy] < b[orderBy]) {
      return -1;
    }
    if (a[orderBy] > b[orderBy]) {
      return 1;
    }
    return 0;
  };
  const sortedUsers = React.useMemo(() => {
    const comparator = getComparator(order, orderBy);
    return users.sort(comparator);
  }, [order, orderBy, users]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <Container>
      <Box sx={{ width: "100%", overflowY: "auto", height: "700px" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 550 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users.length}
              />
              <TableBody>
                {sortedUsers.slice(startIndex, endIndex).map((row) => {
                  return (
                    <TableRow hover key={row.id} sx={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row" padding="none">
                        {row.userEmail ? row.userEmail : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.subscriptionName ? row.subscriptionName : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row.subscriptionActive ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell align="left">
                        {row.subscriptionPrices
                          ? `$${row.subscriptionPrices}`
                          : `-`}
                      </TableCell>
                      {/* <TableCell align="left">
                        {moment(row.createdAt).format("L")}
                      </TableCell> */}
                      <TableCell align="left">
                        {row.subscriptionEndDate
                          ? moment(row.subscriptionEndDate).format("L")
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
}

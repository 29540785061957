import React, { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";

import { setHistory } from "../../redux/reducer";
import avatar from "../../assets/images/user-avatar.png";
import avatar2 from "../../assets/images/AIChat.png";
import { getHistory, getUsers } from "../../graphql/queries";
import {
  createHistory,
  createPromptHistory,
  createUsers,
  updateUsers,
} from "../../graphql/mutations";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

function ChatConversion() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHistoryAvailable, setIsHistoryAvailable] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const chatId = location?.pathname?.split("/")[2];
  const chatRef = useRef(null);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    try {
      if (chatId) {
        getPromptHistories(chatId);
        setIsHistoryAvailable(true);
      } else {
        setIsHistoryAvailable(false);
      }
      // createUser();
    } catch (error) {
      console.log("error: ", error);
    }
  }, [location, chatId]);

  const createUser = async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      const queryResponse = await API.graphql({
        query: getUsers,
        variables: { id: data?.attributes?.sub },
      });
      if (queryResponse?.data?.getUsers === null) {
        await API.graphql(
          graphqlOperation(createUsers, {
            input: {
              id: data?.attributes?.sub,
              cognitoSubId: data?.attributes?.sub,
              subscriptionActive: false,
            },
          })
        );
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getPromptHistories = async (id) => {
    try {
      const queryResponse = await API.graphql({
        query: getHistory,
        variables: { id },
      });
      let finalData = [];
      queryResponse?.data?.getHistory?.conversations?.items?.map((item) => {
        const userMessage = {
          id: item?.id,
          text: item?.prompt,
          sender: "user",
        };
        const parsedMessage = item?.description.replace(
          /\*\*(.*?)\*\*/g,
          (match, text) => {
            if (text.includes(".html")) {
              const inputText = text;
              const squareBracketMatches = inputText.match(/\[(.*?)\]/g);
              const parenthesesMatches = inputText.match(/\((.*?)\)/g);
              return `<span target="_blank" href="https://www.uspto.gov/web/offices/pac/mpep/${parenthesesMatches
                .slice(-1)[0]
                .slice(1, -1)}               
                ">${squareBracketMatches[0]}</span>`;
            } else {
              return match;
            }
          }
        );
        const botMessage = {
          id: item?.id,
          text: parsedMessage,
          sender: "bot",
        };
        finalData.push(userMessage, botMessage);
        return null; // Explicitly return null to satisfy the map function requirements
      });
      setChatHistory(() => finalData);
    } catch (error) {
      // Handle errors
      console.error("getHistory query error:", error);
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsHistoryAvailable(true);
      const data = await Auth.currentAuthenticatedUser();
      const queryResponse = await API.graphql({
        query: getUsers,
        variables: { id: data?.attributes?.sub },
      });
      if (queryResponse?.data?.getUsers?.count >= 3) {
        if (queryResponse?.data?.getUsers?.subscriptionActive) {
          createMessage(queryResponse);
        } else {
          setShowModel(true);
        }
      } else {
        createMessage(queryResponse);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const createMessage = async (queryResponse) => {
    try {
      if (message.trim() !== "") {
        try {
          const newMessage = {
            id: Date.now(),
            text: message,
            sender: "user",
          };
          setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
          setMessage("");

          setLoading(true);
          const response = await axios.post(
            "https://api.roboip.ai/api/question",
            { question: message }
          );
          const botMessage = {
            id: Date.now() + 1,
            text: response.data?.message,
            sender: "bot",
          };
          setChatHistory((prevChatHistory) => [...prevChatHistory, botMessage]);
          setLoading(false);

          let createHistoryRes = {};
          if (!isHistoryAvailable) {
            createHistoryRes = await API.graphql(
              graphqlOperation(createHistory, {
                input: {
                  title: newMessage.text,
                  userId: user.sub,
                },
              })
            );
            dispatch(setHistory(createHistoryRes?.data?.createHistory));
          }

          await API.graphql(
            graphqlOperation(createPromptHistory, {
              input: {
                prompt: newMessage.text,
                description: response.data?.message,
                historyConversationsId: !isHistoryAvailable
                  ? createHistoryRes?.data?.createHistory?.id
                  : chatId,
              },
            })
          );

          await API.graphql(
            graphqlOperation(updateUsers, {
              input: {
                id: user.sub,
                count: queryResponse?.data?.getUsers?.count + 1,
              },
            })
          );
          if (!isHistoryAvailable) {
            navigate(`/chat/${createHistoryRes?.data?.createHistory?.id}`);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full sm:p-4 p-2">
      {!isHistoryAvailable ? (
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 justify-center items-center bg-gray-100">
          <div className="sm:max-w-md max-w-[290px] w-full bg-white shadow-lg rounded-lg p-6">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold">MPEP</h2>
              <p className="text-gray-500">No chat history available</p>
            </div>
            <div className="flex flex-col">
              <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <p className="text-gray-600">
                  You can start the conversation here.
                </p>
              </div>
              <div className="flex items-center">
                <input
                  type="text"
                  className="flex-grow rounded-l-lg border border-gray-300 focus:outline-none sm:px-4 px-2 py-2 w-full sm:text-base text-sm"
                  onChange={handleChange}
                  value={message}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  placeholder="Enter your question here...."
                />
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-r-lg sm:text-lg text-sm"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full md:p-4 p-0">
          <div
            className="flex flex-col h-full overflow-x-auto mb-4 snap-end"
            ref={chatRef}
          >
            <div className="flex flex-col h-full">
              <div className=" ">
                {chatHistory.map((message, index) => (
                  <>
                    {message.sender === "user" ? (
                      <div
                        key={index}
                        className="col-start-6 col-end-13 p-3 rounded-lg"
                      >
                        <div className="flex items-center justify-start flex-row-reverse">
                          <img
                            src={avatar}
                            alt="author"
                            className="inline-block md:h-10 md:w-10 h-5 w-5 rounded-full ring-2 ring-white" // bg-indigo-500
                          />
                          <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl md:max-w-3xl sm:max-w-2xl">
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              dangerouslySetInnerHTML={{
                                __html: message.text,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" p-3 rounded-lg">
                        <div className="flex flex-row md:items-center items-start">
                          <img
                            src={avatar2}
                            alt="author"
                            className="inline-block md:h-10 md:w-10 h-5 w-5 rounded-full ring-2 ring-white"
                          />
                          <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl md:max-w-3xl sm:max-w-2xl">
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              dangerouslySetInnerHTML={{
                                __html: message.text.replace(/\*\*/g, ""),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
                {loading && (
                  <div className={` p-3 rounded-lg animate-pulse`}>
                    <div className="flex flex-row md:items-center items-start">
                      <img
                        src={avatar2}
                        alt="author"
                        className="inline-block h-10 w-10 rounded-full ring-2 ring-white" // bg-indigo-500
                      />
                      <div className="animate-pulse flex w-80 ml-3">
                        <div className="flex-1 space-y-6 py-1 w-100">
                          <div className="h-2 bg-slate-200 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-200 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center h-16 rounded-xl sm:bg-white bg-transparent w-full sm:px-4">
            <div className="flex-grow">
              <div className="relative w-full">
                <input
                  type="text"
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10 sm:text-base text-sm"
                  onChange={handleChange}
                  value={message}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  placeholder="Enter your question here...."
                />
              </div>
            </div>
            <div className="sm:ml-4 ml-2">
              <button
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 sm:rounded-2xl rounded-xl text-white sm:px-5 sm:py-3 p-2 flex-shrink-0"
                onClick={handleSubmit}
              >
                <span className="sm:block hidden">Send</span>
                <span className="sm:ml-2 ml-0">
                  <svg
                    className="w-5 h-5 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={showModel} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowModel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 flex"
                        >
                          Payment notification
                          <InformationCircleIcon className="h-5 w-5 text-indigo-500 ml-1" />
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Thank you for your interest in our website. To
                            access the full content and enjoy all the features,
                            please subscribe by proccessing with following link.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-500 hover:bg-indigo-600  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setShowModel(false);
                        navigate("/pricing");
                      }}
                    >
                      Process to payment
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default ChatConversion;

import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Auth, Storage } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import {
  UserIcon,
  ArrowRightIcon,
  PhoneIcon,
  AtSymbolIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import AIicon from "../../assets/images/AIicon.png";
import { setUser } from "../../redux/reducer";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  picture: Yup.mixed(),
});

const UserProfileForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    picture: "",
  });
  const [imageUrl, setImageUrl] = useState(null);

  const getUser = useCallback(async () => {
    const user = await Auth.currentAuthenticatedUser();
    setInitialValues({
      firstName: user?.attributes?.given_name,
      email: user?.attributes?.email,
      phoneNumber: user?.attributes?.phone_number.replace(/^\D+/, ""),
      picture: user?.attributes?.picture,
    });
    user?.attributes?.picture && getImage(user);
    setUserData(user);
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);
  const getImage = async (user) => {
    try {
      const imageKey = `${user?.attributes?.sub}/${user?.attributes?.picture}`;
      const url = await Storage.get(imageKey);
      setImageUrl(url);
    } catch (error) {
      console.error("Error fetching image from S3:", error);
    }
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    setIsLoading(true);
    const attributes = {
      given_name: values.firstName,
      email: values.email,
      phone_number: `+1${values.phoneNumber.replace(/[-()]/g, "")}`,
    };
    if (values.picture.name) {
      await Storage.put(
        `${userData?.attributes?.sub}/${values.picture.name}`,
        values.picture
      );
      attributes.picture = values.picture
        ? values.picture.name
        : initialValues.picture;
    }
    const updateData = await Auth.updateUserAttributes(userData, attributes);
    setIsLoading(false);
    toast.success("User update successfully");
    getUser();
    dispatch(setUser(updateData));
  };

  return (
    <div className="flex flex-col flex-auto flex-shrink-0 justify-center items-center md:mt-14 sm:mt-10 mt-7">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg sm:p-6 p-4">
        <div className="flex justify-center	relative">
          <ArrowLeftIcon
            fontSize={10}
            className="md:w-7 md:h-7 w-5 h-5 cursor-pointer absolute left-0 top-0"
            onClick={() => navigate("/")}
          />
          <h2 className="sm:text-2xl text-xl font-bold mb-6">
            Update Your Profile
          </h2>
        </div>
        <div className="flex flex-col items-center">
          <div className="md:h-40 md:w-40 sm:h-28 sm:w-28 h-20 w-20 rounded-full border overflow-hidden">
            <img
              src={imageUrl ? imageUrl : AIicon}
              alt="Avatar"
              className="h-full w-full"
            />
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="mb-4 mt-8">
                <Field
                  type="file"
                  id="picture"
                  name="picture"
                  value={undefined}
                  onChange={(event) => {
                    setFieldValue("picture", event.currentTarget.files[0]);
                  }}
                  className="w-[70%] border border-gray-300 rounded-lg px-3 md:py-10 py-5 w-full focus:outline-none"
                />
                <ErrorMessage
                  name="picture"
                  component="p"
                  className="text-red-500 mt-1"
                />
              </div>

              <div className="mb-4">
                <div className="relative">
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    className="pr-10 border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none"
                  />
                  <div className="bg-transparent absolute right-3 top-3  ">
                    <UserIcon className="w-5 h-5 " />
                  </div>
                </div>
                <ErrorMessage
                  name="firstName"
                  component="p"
                  className="text-red-500 mt-1"
                />
              </div>
              <div className="mb-4">
                <div className="relative">
                  <Field
                    disabled={true}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email address"
                    className="pr-10 border border-gray-300  rounded-lg px-3 py-2 w-full focus:outline-none"
                  />
                  <div className="bg-transparent absolute right-3 top-3  ">
                    <AtSymbolIcon className="w-5 h-5 " />
                  </div>
                </div>
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-500 mt-1"
                />
              </div>
              <div className="mb-4">
                <div className="relative">
                  <Field
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone number"
                    className="pr-10 border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none"
                  />
                  <div className="bg-transparent absolute right-3 top-3  ">
                    <PhoneIcon className="w-5 h-5" />
                  </div>
                </div>
                <ErrorMessage
                  name="phoneNumber"
                  component="p"
                  className="text-red-500 mt-1"
                />
              </div>

              <Link
                to="/changePassword"
                className="font-bold text-indigo-500 hover:text-indigo-600"
              >
                <p className="text-right md:mb-14 mb-6">Reset your password</p>
              </Link>
              <div className="text-center">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` w-full py-2 px-4  rounded-2xl bg-indigo-500 hover:bg-indigo-600  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    isLoading
                      ? "opacity-50 cursor-not-allowed flex justify-center"
                      : ""
                  }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                      ></path>
                    </svg>
                  ) : (
                    <span className="flex justify-between">
                      Update Profile
                      <ArrowRightIcon className="h-6 w-6 ml-2" />
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserProfileForm;

import React, { useState } from "react";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Confirm password must match")
    .required("Confirm password is required"),
});

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      // console.log(values);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        values.oldPassword,
        values.newPassword
      );
      toast.success("Password change successfully");
      setIsLoading(false);
      setTimeout(() => {
        navigate("/profile");
      }, 1500);
    } catch (error) {
      toast.error(
        error.message || "Somithing went wrong, please try after sometime!"
      );
      setIsLoading(false);
      console.log("error: ", error);
    }
  };

  return (
    <div className="flex flex-col flex-auto flex-shrink-0 justify-center items-center h-5/9">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 flex items-center mb-5">
          <ArrowLeftIcon
            className="w-8 h-8 cursor-pointer"
            onClick={() => navigate("/profile")}
          />
          &nbsp;&nbsp;&nbsp; Change password
        </h2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Old Password
                </label>
              </div>
              <Field
                id="oldPassword"
                name="oldPassword"
                type="password"
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <ErrorMessage
                name="oldPassword"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  New Password
                </label>
              </div>
              <Field
                id="newPassword"
                name="newPassword"
                type="password"
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <ErrorMessage
                name="newPassword"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <Field
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <ErrorMessage
                name="confirmPassword"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                disabled={isLoading}
                className={` w-full py-2 px-4 mt-5 rounded-2xl bg-indigo-500 hover:bg-indigo-600  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed flex justify-center"
                    : ""
                }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                    ></path>
                  </svg>
                ) : (
                  <span className="flex justify-between">
                    change password
                    <ArrowRightIcon className="h-6 w-6 ml-2" />
                  </span>
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;

import React from "react";
import { useSelector } from "react-redux";

function PricingPage() {
  const user = useSelector((state) => state.user);
  return (
    user.sub && (
      <>
        <p>{console.log("pricing table user.sub:", user.sub)}</p>
        <stripe-pricing-table
          pricing-table-id="prctbl_1NNDwTIxzvefeVsQ0mz2awfk"
          publishable-key="pk_live_QV3WJTUMLWAH6GAsAJIrEgcP"
          client-reference-id={user.sub}
        ></stripe-pricing-table>
      </>
    )
  );
}

export default PricingPage;

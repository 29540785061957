import React, { useEffect } from "react";

const data = [
  {
    id: "1",
    title: "Acceptance of Terms",
    paragraph:
      "By accessing or using the Platform, you confirm that you have read, understood, and agree to be bound by these Terms, our Privacy Policy, and any additional terms and conditions that may apply to specific features or services provided by RoboIP.",
  },
  {
    id: "2",
    title: "Description of Services",
    paragraph:
      " RoboIP provides a unique and proprietary AI bot trained on the Manual of Patent Examining Procedure (MPEP). Our AI bot is designed to assist users in obtaining answers to their questions related to the MPEP. By creating an account and subscribing to our services, you gain access to the AI bot, which offers personalized responses based on the content of the MPEP.",
  },
  {
    id: "3",
    title: "User Accounts and Subscriptions",
    subPoint: [
      {
        id: "a",
        title: "Account Creation",
        paragraph:
          "To use the Platform, you must create a user account. You agree to provide accurate and complete information during the registration process and to keep your account credentials confidential. You are responsible for all activities that occur under your account.",
      },
      {
        id: "b",
        title: "Subscription Plans",
        paragraph:
          "RoboIP offers monthly, yearly, and enterprise-level subscription plans. Each plan grants you access to the AI bot and related services according to the chosen subscription duration and features. You may be required to provide billing information and choose a payment method to subscribe to our services.",
      },
      {
        id: "c",
        title: "Billing and Payments",
        paragraph:
          "By subscribing to our services, you authorize RoboIP to charge the applicable fees to your chosen payment method. Subscription fees are non-refundable, and you are responsible for timely payment of all charges associated with your account. You may cancel your subscription at any time. We will no longer charge you anything once you cancel your subscription. We reserve the right to modify our pricing, pricing policies, features and access restrictions at any time.",
      },
      {
        id: "d",
        title: "Cancellation",
        paragraph:
          "You may cancel your subscription at any time through your account settings. Upon cancellation, your access to the AI bot and related services will continue until the end of the billing period, and no further charges will be incurred.",
      },
    ],
  },
  {
    id: "4",
    title: "Use of the Platform",
    subPoint: [
      {
        id: "a",
        title: "Acceptable Use",
        paragraph:
          "You agree to use the Platform in compliance with these Terms, applicable laws, and any additional guidelines provided by RoboIP. You shall not use the Platform for any unlawful, fraudulent, or unauthorized purposes.",
      },
      {
        id: "b",
        title: "User Content",
        paragraph:
          "When using the Platform, you may submit questions and receive responses from the AI bot. You understand and agree that any content you submit is your responsibility, and you grant RoboIP a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and display such content for the purpose of providing and improving our services.",
      },
    ],
  },
  {
    id: "5",
    title: "Intellectual Property",
    paragraph:
      " RoboIP retains all intellectual property rights in the Platform, including the AI bot and all associated content. You acknowledge and agree that the content displayed on the Platform is protected by copyright, trademark, and other laws. You shall not modify, reproduce, distribute, or create derivative works based on the Platform's content without our prior written consent.",
  },
  {
    id: "6",
    title: "Limitation of Liability",
    paragraph:
      " To the extent permitted by law, RoboIP and its affiliates, directors, employees, and agents shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Platform. We do not guarantee the accuracy, completeness, or reliability of the information provided by the AI bot.",
  },
  {
    id: "7",
    title: "Termination",
    paragraph:
      "RoboIP reserves the right to suspend or terminate your access to the Platform, in whole or in part, at any time and for any reason, including but not limited to violations of these Terms, fraudulent activity, or any other conduct that we deem harmful to the Platform or its users. In the event of termination, your access to the AI bot and related services will be immediately revoked.",
    subPoint: [
      {
        id: "a",
        title: "Privacy",
        paragraph:
          " RoboIP respects your privacy and handles your personal information in accordance with our Privacy Policy. By using the Platform, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy. ",
      },
      {
        id: "b",
        title: "Modifications",
        paragraph:
          " Modifications to the Terms RoboIP reserves the right to modify or update these Terms at any time, without prior notice. Any changes will be effective upon their posting on the Platform. It is your responsibility to review these Terms periodically. Continued use of the Platform after any modifications signifies your acceptance of the revised Terms. ",
      },
      {
        id: "c",
        title: "Governing Law and Dispute Resolution",
        paragraph:
          "  These Terms shall be governed by and construed in accordance with the laws of Pennsylvania. Any disputes arising out of or relating to these Terms or the use of the Platform shall be resolved through binding arbitration in accordance with the rules of [Arbitration Organization]. The arbitration shall take place in [City, Jurisdiction], and the language of the arbitration shall be [Language]. Each party shall bear its own costs incurred in the arbitration proceedings. ",
      },
      {
        id: "d",
        title: "Severability",
        paragraph:
          " If any provision of these Terms is found to be unenforceable or invalid, such provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect. ",
      },
      {
        id: "e",
        title: "Entire Agreement",
        paragraph:
          " These Terms, together with our Privacy Policy, constitute the entire agreement between you and RoboIP regarding your use of the Platform, superseding any prior agreements or understandings.Thank you for reading and agreeing to our Terms and Conditions. We hope you find our Platform valuable and enjoy the benefits of our unique AI bot trained on the MPEP. If you have any questions or concerns, please contact us at nomad@roboip.ai. ",
      },
    ],
  },
];

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <div className="md:mb-12 mb-6 md:mt-10 mt-6">
          <h2 className="md:text-5xl text-3xl font-bold mt-10">
            Terms & Conditions
          </h2>
          <p className="sm:text-lg text-base sm:mt-3 mt-1 mb-6">
            Effective Date: 6/7/2023
          </p>
          <p className="sm:text-lg text-base mb-8">
            Welcome to RoboIP! These Terms and Conditions ("Terms") govern your
            use of the RoboIP website, services, and all related applications
            (collectively referred to as the "Platform"). The Platform is owned
            and operated by Wysebridge, doing business as RoboIP ("RoboIP,"
            "we," or "us"). By accessing or using the Platform, you agree to be
            bound by these Terms. Please read them carefully before using our
            services.
          </p>
          <div>
            {data &&
              data.map((row, index) => (
                <div key={index}>
                  <ul className="flex gap-2">
                    <li>{index + 1}.</li>
                    <li className="sm:text-lg text-base">
                      <span className="font-bold"> {row.title} </span>
                      {row.paragraph}
                    </li>
                  </ul>
                  {row.hasOwnProperty("subPoint") &&
                    row.subPoint.map((row) => (
                      <>
                        <ul className=" flex gap-2 ml-6">
                          <li className="sm:text-lg text-base">
                            <ul className="flex gap-1">
                              <li> {row.id}. </li>
                              <li>
                                <span className="font-bold">{row.title} :</span>
                                {row.paragraph}
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

import AIBanner from "../assets/images/RoboIP.png";

const Footer = () => {
  const navigate = useNavigate();
  const locationPath = window.location.pathname;
  const [isFooter, setIsFooter] = useState(false);

  useEffect(() => {
    if (locationPath === "/") {
      setIsFooter(true);
    } else {
      setIsFooter(false);
    }
  }, [locationPath]);

  return (
    <>
      {isFooter && (
        <div className="border-t border-black-100">
          <footer className="container sm:my-8 my-5">
            <div className="sm:flex justify-between items-center sm:gap-10 gap-3 ">
              <img src={AIBanner} alt="banner" className="lg:w-[180px] w-[120px]" />
              <div className="sm:flex gap-5 sm:my-0 my-3">
                <p className="font-bold cursor-pointer md:text-lg text-base">Pricing</p>
                <p className="font-bold cursor-pointer md:text-lg text-base">Contact us</p>
                <p className="font-bold cursor-pointer md:text-lg text-base" onClick={() => navigate("/terms")}>
                  Terms & Conditions
                </p>
                <p
                  className="font-bold cursor-pointer md:text-lg text-base"
                  onClick={() => {
                    navigate("/privacy");
                  }}
                >
                  Privacy Policy
                </p>
              </div>
              <div className="flex sm:gap-5 gap-3 items-center">
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/roboip/">
                  <LinkedInIcon className="cursor-pointer" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/robo_IP">
                  <TwitterIcon className="cursor-pointer" />
                </a>
              </div>
            </div>
            <div className="sm:text-right sm:mt-0 mt-3">
              <p className="text-sm">(r) All Rights Reserved 2023 | RoboIP</p>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default Footer;

"use client";
import { useState } from "react";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import AIBanner from "../../assets/images/RoboIP.png";

const validationSchema = yup.object().shape({
  resetCode: yup.string().required("Reset code is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one numeric digit, and one special character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Confirm password must match")
    .required("Confirm password is required"),
});

const initialValues = {
  resetCode: "",
  newPassword: "",
  confirmPassword: "",
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState();

  const handleForgotPassword = async (values) => {
    try {
      setIsLoading(true);
      setUsername(values.username);
      await Auth.forgotPassword(values.username);
      toast.success("Reset code sent successfully");
      setIsCodeSent(true);
      setIsLoading(false);
      // Code sent successfully, show success message or redirect
    } catch (error) {
      setIsLoading(false);

      console.log("forgot error: ", error);
      toast.error(error.message || "Something went wrong");
    }
  };

  const handleResetPassword = async (values) => {
    try {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(
        username,
        values.resetCode,
        values.newPassword
      );
      toast.success("Password reset successfully");
      setIsLoading(false);
      setTimeout(() => {
        navigate("/signin");
      }, 1000);
      // Password reset successful, redirect or show success message
    } catch (error) {
      setIsLoading(false);
      console.log("Reseat error: ", error);
      toast.error(error.message || "Something went wrong");
    }
  };

  return (
    <div className="py-24 h-screen ">
      <div className="p-10 w-[450px] m-auto shadow-2xl rounded-2xl ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img src={AIBanner} alt="banner" className="mx-auto h-12 w-auto" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 flex items-center">
            <ArrowLeftIcon
              fontSize={10}
              className="w-10 h-10 cursor-pointer"
              onClick={() => navigate("/signin")}
            />
            &nbsp;&nbsp;&nbsp; Forgot your account password?
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {!isCodeSent ? (
            <Formik
              initialValues={{ username: "" }}
              validationSchema={yup.object().shape({
                username: yup.string().required("Email is required"),
              })}
              onSubmit={handleForgotPassword}
              name="sendcode"
            >
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <Field
                    id="username"
                    name="username"
                    type="email"
                    placeholder="Enter email"
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage
                    name="username"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`flex w-full pl-2 justify-center rounded-md bg-indigo-500 hover:bg-indigo-600  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                  ${isLoading ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                      ></path>
                    </svg>
                  ) : (
                    <strong>Send Reset Code</strong>
                  )}
                </button>
              </Form>
            </Formik>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleResetPassword}
              name="verification"
            >
              <Form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Reset code
                  </label>
                  <Field
                    id="resetCode"
                    name="resetCode"
                    type="text"
                    placeholder="Enter reset code"
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage
                    name="resetCode"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      New Password
                    </label>
                  </div>
                  <Field
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Confirm Password
                    </label>
                  </div>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`flex w-full pl-2 justify-center rounded-md bg-indigo-500 hover:bg-indigo-600  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
                      ></path>
                    </svg>
                  ) : (
                    <strong>Submit</strong>
                  )}
                </button>
              </Form>
            </Formik>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

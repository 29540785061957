import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import ChatConversion from "./ChatConversion";

const ChatBot = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setShowDrawer(windowWidth < 992);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex h-5/9 antialiased text-gray-800 bg-white">
      <div className="flex flex-row h-full w-full overflow-x-hidden overflow-y-hidden">
        {!showDrawer && <SideBar showDrawer={showDrawer} />}
        <div className="flex flex-col flex-auto h-full p-2">
          <ChatConversion showDrawer={showDrawer} />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://yt6rqpmbyfhnvlvcooneuw7rdy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4jq7oa2yazczxm4nhclbj2ooyu",
    "aws_cognito_identity_pool_id": "us-east-2:5406e777-8cf7-4c7f-bfd9-32e3a82b19dc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_MmpLouQQO",
    "aws_user_pools_web_client_id": "3424d1036gd497kn3a9abbmfrb",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mpepforntend-bucket143138-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  promptHisotry: [],
  history: {},
  subscription: {},
  articles: [],
};

export const rootReducerSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setPromptHisotry: (state, { payload }) => {
      state.promptHisotry = payload;
    },
    setHistory: (state, { payload }) => {
      state.history = payload;
    },
    setSubscription: (state, { payload }) => {
      state.subscription = payload;
    },
    setArticles: (state, { payload }) => {
      state.articles = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setPromptHisotry,
  setHistory,
  setSubscription,
  setArticles,
} = rootReducerSlice.actions;

export default rootReducerSlice.reducer;

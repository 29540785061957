/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      title
      userId
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      title
      userId
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      title
      userId
      conversations {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPromptHistory = /* GraphQL */ `
  mutation CreatePromptHistory(
    $input: CreatePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    createPromptHistory(input: $input, condition: $condition) {
      id
      historyConversationsId
      prompt
      description
      sender
      createdAt
      updatedAt
    }
  }
`;
export const updatePromptHistory = /* GraphQL */ `
  mutation UpdatePromptHistory(
    $input: UpdatePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    updatePromptHistory(input: $input, condition: $condition) {
      id
      historyConversationsId
      prompt
      description
      sender
      createdAt
      updatedAt
    }
  }
`;
export const deletePromptHistory = /* GraphQL */ `
  mutation DeletePromptHistory(
    $input: DeletePromptHistoryInput!
    $condition: ModelPromptHistoryConditionInput
  ) {
    deletePromptHistory(input: $input, condition: $condition) {
      id
      historyConversationsId
      prompt
      description
      sender
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      cognitoSubId
      userEmail
      subscriptionId
      subscriptionName
      subscriptionActive
      subscriptionPrices
      subscriptionEndDate
      subscriptionDescription
      subscriptionType
      paymentMethod
      paymentCard
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      cognitoSubId
      userEmail
      subscriptionId
      subscriptionName
      subscriptionActive
      subscriptionPrices
      subscriptionEndDate
      subscriptionDescription
      subscriptionType
      paymentMethod
      paymentCard
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      cognitoSubId
      userEmail
      subscriptionId
      subscriptionName
      subscriptionActive
      subscriptionPrices
      subscriptionEndDate
      subscriptionDescription
      subscriptionType
      paymentMethod
      paymentCard
      count
      createdAt
      updatedAt
    }
  }
`;
export const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      id
      body
      title
      urlSlug
      createdAt
      updatedAt
    }
  }
`;
export const updateArticles = /* GraphQL */ `
  mutation UpdateArticles(
    $input: UpdateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    updateArticles(input: $input, condition: $condition) {
      id
      body
      title
      urlSlug
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticles = /* GraphQL */ `
  mutation DeleteArticles(
    $input: DeleteArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    deleteArticles(input: $input, condition: $condition) {
      id
      body
      title
      urlSlug
      createdAt
      updatedAt
    }
  }
`;

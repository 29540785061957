import React, { useState, Fragment, useEffect, useCallback } from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { ToastContainer, toast } from "react-toastify";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserIcon,
  ArrowLeftOnRectangleIcon,
  ArchiveBoxIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";
import { DocumentChartBarIcon } from "@heroicons/react/24/solid";

import { setPromptHisotry } from "../../redux/reducer";
import { listHistories } from "../../graphql/queries";
import { createHistory } from "../../graphql/mutations";

const SideBar = ({ showDrawer, setIsOpen, isOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const history = useSelector((state) => state.history);

  const [showModel, setShowModel] = useState(false);
  const [historyData, setHistoryData] = useState({
    todayData: [],
    yesterdayData: [],
    previous7DaysData: [],
  });
  // const [imageUrl, setImageUrl] = useState(null);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [todayCollapse, setTodayCollapse] = useState(true);
  const [yestrdayCollapse, setYestrdayCollapse] = useState(false);
  const [pr7collapse, setPr7collapse] = useState(false);

  // const getDetail = async () => {
  //   try {
  //     const imageKey = `${user?.sub}/${user?.picture}`;
  //      const url = await Storage.get(imageKey);
  //      setImageUrl(url);
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };

  // useEffect(() => {
  //   getDetail();
  // });

  const getPromptHistories = useCallback(async () => {
    try {
      const authData = await Auth.currentAuthenticatedUser();
      setUser(authData?.attributes);
      const queryResponse = await API.graphql({
        query: listHistories,
        variables: {
          filter: {
            userId: {
              contains: authData?.attributes?.sub,
            },
          },
        },
      });
      dispatch(setPromptHisotry(queryResponse?.data?.listHistories?.items));
      // Handle the response
      const data = queryResponse?.data?.listHistories?.items;

      // Get today's date
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight

      // Get yesterday's date
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0); // Set time to midnight

      // Calculate the date 7 days ago
      const previous7Days = new Date();
      previous7Days.setDate(previous7Days.getDate() - 7);
      previous7Days.setHours(0, 0, 0, 0); // Set time to midnight

      // Filter data based on createdAt
      const todayData = data.filter(
        (item) => new Date(item.createdAt) >= today
      );
      const yesterdayData = data.filter(
        (item) =>
          new Date(item.createdAt) >= yesterday &&
          new Date(item.createdAt) < today
      );
      const previous7DaysData = data.filter(
        (item) =>
          new Date(item.createdAt) >= previous7Days &&
          new Date(item.createdAt) < yesterday
      );

      // console.log("Today Data:", todayData);
      // console.log("Yesterday Data:", yesterdayData);
      // console.log("Previous 7 Days Data:", previous7DaysData);
      setHistoryData({ todayData, yesterdayData, previous7DaysData });
    } catch (error) {
      // Handle errors
      console.error("Query error:", error);
    }
  }, [dispatch, setUser]);

  useEffect(() => {
    getPromptHistories();
  }, [getPromptHistories]);

  const newChat = async () => {
    try {
      setIsLoading(true);
      const data = await API.graphql(
        graphqlOperation(createHistory, {
          input: {
            title: `Chat - ${new Date().toDateString()}`,
            userId: user.sub,
          },
        })
      );
      getPromptHistories();
      setIsLoading(false);
      navigate(`/chat/${data?.data?.createHistory?.id}`);
    } catch (error) {
      setIsLoading(false);
      console.log("signin error: ", error);
      toast.error(error?.message || "Something went wrong");
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      navigate("/signin");
      // Redirect or perform any additional actions after successful logout
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };
  return (
    <>
      <div
        className={`flex flex-col  w-64 h-full bg-white flex-shrink-0 ${
          showDrawer ? `px-3` : `pl-6 `
        }`}
      >
        <button
          onClick={() => {
            newChat();
            setIsOpen && setIsOpen(!isOpen);
          }}
          disabled={isLoading}
          className={`flex w-full justify-between	justify-center md:mt-6 mt-2 mb-6 rounded-2xl bg-indigo-500 hover:bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4 ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {isLoading ? (
            <svg
              className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
              ></path>
            </svg>
          ) : (
            <>
              <strong>Ask a question </strong>
              <ArrowRightIcon className="ml-2 w-6 h-6" />
            </>
          )}
        </button>

        <div className="h-full flex flex-col justify-between ">
          <div className="max-h-[610px] overflow-y-auto">
            <div className="flex flex-col">
              <div
                className="flex flex-row items-center justify-between cursor-pointer"
                onClick={() => setTodayCollapse(!todayCollapse)}
              >
                <div className="flex gap-3">
                  <DocumentChartBarIcon className="w-5 h-5" />
                  <span className="font-bold">Today</span>
                </div>
                {todayCollapse === true ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </div>
              {todayCollapse && (
                <div className="flex flex-col space-y-1  overflow-y-auto">
                  {historyData?.todayData?.map((item, i) => (
                    <button
                      className="mt-4 flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                      key={i}
                      onClick={() => {
                        navigate(`/chat/${item?.id}`);
                        setIsOpen && setIsOpen(!isOpen);
                      }}
                    >
                      <div className="ml-2 text-sm font-semibold">
                        {`${item.title.substring(0, 23)}...`}
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className="flex flex-col mt-4">
              <div
                className="flex flex-row items-center justify-between cursor-pointer"
                onClick={() => setYestrdayCollapse(!yestrdayCollapse)}
              >
                <div className="flex gap-3">
                  <DocumentChartBarIcon className="w-5 h-5" />
                  <span className="font-bold">Yesterday</span>
                </div>
                {yestrdayCollapse === true ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </div>
              {yestrdayCollapse && (
                <div className="flex flex-col space-y-1 mt-4 overflow-y-auto">
                  {historyData?.yesterdayData?.map((item, i) => (
                    <button
                      className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                      key={i + 1}
                      onClick={() => {
                        navigate(`/chat/${item?.id}`);
                        setIsOpen && setIsOpen(!isOpen);
                      }}
                    >
                      <div className="ml-2 text-sm font-semibold">{`${item.title.substring(
                        0,
                        23
                      )}...`}</div>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col mt-4">
              <div
                className="flex flex-row items-center justify-between cursor-pointer"
                onClick={() => setPr7collapse(!pr7collapse)}
              >
                <div className="flex gap-3">
                  <DocumentChartBarIcon className="w-5 h-5" />
                  <span className="font-bold">Previous 7 Days</span>
                </div>
                {pr7collapse === true ? (
                  <ChevronUpIcon className="w-5 h-5" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5" />
                )}
              </div>
              {pr7collapse && (
                <div className="flex flex-col space-y-1 mt-4 overflow-y-auto">
                  {historyData?.previous7DaysData?.map((item, i) => (
                    <button
                      className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                      key={i}
                      onClick={() => {
                        navigate(`/chat/${item?.id}`);
                        setIsOpen && setIsOpen(!isOpen);
                      }}
                    >
                      <div className="ml-2 text-sm font-semibold">{`${item.title.substring(
                        0,
                        23
                      )}...`}</div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="mb-8">
            <div
              className="flex gap-3 mt-3 cursor-pointer"
              onClick={() => {
                navigate("/articles");
                setIsOpen && setIsOpen(!isOpen);
              }}
            >
              <UserIcon className="w-5 h-5" />
              <p className="uppercase">Articles</p>
            </div>
            <div
              className="flex gap-3 mt-3 cursor-pointer"
              onClick={() => {
                navigate("/profile");
                setIsOpen && setIsOpen(!isOpen);
              }}
            >
              <UserIcon className="w-5 h-5" />
              <p className="uppercase"> PROFILE </p>
            </div>
            <div
              className="flex gap-3 mt-3 cursor-pointer"
              onClick={() => {
                navigate("/subscription");
                setIsOpen && setIsOpen(!isOpen);
              }}
            >
              <ArchiveBoxIcon className="w-5 h-5" />
              <p className="uppercase"> Subscription</p>
            </div>
            {user &&
              user.email &&
              user.email.length > 0 &&
              user?.email?.toLocaleLowerCase() === "bryan@roboip.ai" && (
                <div
                  className="flex gap-3 mt-3 cursor-pointer"
                  onClick={() => {
                    navigate("/listUsers");
                    setIsOpen && setIsOpen(!isOpen);
                  }}
                >
                  <ListBulletIcon className="w-5 h-5" />
                  <p className="uppercase"> User List </p>
                </div>
              )}

            <div
              className="flex gap-3 mt-3 cursor-pointer"
              onClick={() => {
                handleLogout();
                setIsOpen && setIsOpen(!isOpen);
              }}
            >
              <ArrowLeftOnRectangleIcon className="w-5 h-5" />
              <p className="uppercase"> Log out </p>
            </div>
          </div>
        </div>

        <Transition.Root show={showModel} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setShowModel}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            About
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                              This tool aims to make the{" "}
                              <a
                                href="https://www.uspto.gov/web/offices/pac/mpep/index.html"
                                target="_blank"
                                className="underline"
                                rel="noreferrer"
                              >
                                MPEP
                              </a>
                              (Manual of Patent Examination and Procedure) more
                              accessible to everyone. The MPEP is what guides
                              patent examiners at the US Patent and Trademark
                              Office{" "}
                              <a
                                href="https://www.uspto.gov/"
                                target="_blank"
                                className="underline"
                                rel="noreferrer"
                              >
                                (USPTO)
                              </a>{" "}
                              when they examine patent applications. It is also
                              what guides patent lawyers and patent agents when
                              preparing patent applications for their clients.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-500 hover:bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        onClick={() => setShowModel(false)}
                      >
                        Okay
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <ToastContainer />
      </div>
    </>
  );
};

export default SideBar;

import Signin from "../components/auth/Signin";
import Signup from "../components/auth/Signup";
import Verification from "../components/auth/Verification";
import ForgotPassword from "../components/auth/ForgotPassword";
import UserProfile from "../components/auth/UserProfile";
import ChangePassword from "../components/auth/ChangePassword";
import Chat from "../components/chat/ChatBot";
import PricingPage from "../components/subscription/PricingPage";
import SubscriptionDetail from "../components/subscription/SubscriptionDetail";
import TermsConditions from "../components/Landing/TermsConditions";
import PrivacyPolicy from "../components/Landing/PrivacyPolicy";
import LandingPage from "../components/Landing/Landing";
import ListUserTbl from "../components/Table/ListUserTbl";
import Articles from "../components/articles/Articles";
import Article from "../components/articles/Article";

export const ROUTES = [
  {
    path: "/",
    isPrivate: false,
    Component: LandingPage,
    tabTitle: "RoboIP",
  },
  {
    path: "/signin",
    isPrivate: false,
    Component: Signin,
    tabTitle: "RoboIP",
  },
  {
    path: "/signup",
    isPrivate: false,
    Component: Signup,
    tabTitle: "RoboIP",
  },
  {
    path: "/verification",
    isPrivate: false,
    Component: Verification,
    tabTitle: "RoboIP",
  },
  {
    path: "/forgotPassword",
    isPrivate: false,
    Component: ForgotPassword,
    tabTitle: "RoboIP",
  },
  {
    path: "/terms",
    isPrivate: false,
    Component: TermsConditions,
    tabTitle: "RoboIP",
  },
  {
    path: "/privacy",
    isPrivate: false,
    Component: PrivacyPolicy,
    tabTitle: "RoboIP",
  },
  {
    path: "/chat",
    isPrivate: true,
    Component: Chat,
    tabTitle: "RoboIP",
  },
  {
    path: "/chat/:id",
    isPrivate: true,
    Component: Chat,
    tabTitle: "RoboIP",
  },
  {
    path: "/profile",
    isPrivate: true,
    Component: UserProfile,
    tabTitle: "RoboIP",
  },
  {
    path: "/changePassword",
    isPrivate: true,
    Component: ChangePassword,
    tabTitle: "RoboIP",
  },
  {
    path: "/subscription",
    isPrivate: true,
    Component: SubscriptionDetail,
    tabTitle: "RoboIP",
  },
  {
    path: "/pricing",
    isPrivate: true,
    Component: PricingPage,
    tabTitle: "RoboIP",
  },
  {
    path: "/listUsers",
    isPrivate: true,
    Component: ListUserTbl,
    tabTitle: "RoboIP",
  },
  {
    path: "/articles",
    isPrivate: false,
    Component: Articles,
    tabTitle: "RoboIP",
  },
  {
    path: "/article/:urlSlug",
    isPrivate: false,
    Component: Article,
    tabTitle: "RoboIP",
  },
];

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { API } from "aws-amplify";
import { listArticles } from "../../graphql/queries";

const Articles = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [articleDetail, setArticleDetail] = useState();

  const articleSlug = pathname?.split("/")[2];

  useEffect(() => {
    if (articleSlug) getArticleDetail();
  }, [articleSlug]);

  const getArticleDetail = async () => {
    try {
      setIsLoading(true);

      let nextToken;
      while (nextToken !== null) {
        const queryResponse = await API.graphql({
          query: listArticles,
          variables: {
            filter: {
              urlSlug: {
                eq: articleSlug,
              },
            },
            nextToken: nextToken,
          },
        });
        if (queryResponse?.data?.listArticles?.items.length === 0) {
          nextToken = queryResponse?.data?.listArticles?.nextToken;
        } else {
          setArticleDetail(queryResponse?.data?.listArticles?.items[0]);
          break;
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col h-full sm:p-4">
      <div className="flex font-semibold mb-6">
        <ArrowLeftIcon className="w-6 h-6 cursor-pointer mr-2" onClick={() => navigate("/articles")} />
        Back to article
      </div>
      {isLoading ? (
        <div className="flex justify-center">
          <svg
            className="animate-spin -ml-1 mr-2 h-5 w-5 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.746-.879 6.5-2.343l-3-4.95A3.993 3.993 0 0112 16a3.993 3.993 0 01-3.5-2.343l-3 4.95A7.963 7.963 0 0112 20zm5.5-9.343l3-4.95A7.963 7.963 0 0120 12h-4a3.993 3.993 0 01-3.5 2.343l-3 4.95A7.963 7.963 0 0112 20c2.485 0 4.746-.879 6.5-2.343z"
            ></path>
          </svg>
          Loading...
        </div>
      ) : (
        <div className="container mx-auto overflow-y-auto mb-16">
          <div>
            <img src={articleDetail?.imageAssets} className="w-full h-[32rem] object-cover rounded-md" />
            <div className="articleC" dangerouslySetInnerHTML={{ __html: articleDetail?.body }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Articles;
